import React from "react";

import ContactForm from "../../../../ContactForm";

const Schmierstoffe = ({ name = "schmierstoffe" }) => {
    return (
        <ContactForm
            // phoneHeadline="0 42 62 - 79 94 22"
            //phoneLink="+494262799422"
            emailHeadline="schmierstoffe@hoyer.de"
            urlEnding="schmierstoffe"
            canSendStorageString={`${name}::contact-form:sent-at`}
            pushToGTMString={`${name}:contact-form`}
        />
    );
};

export default Schmierstoffe;
